import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Wallet, ArrowsDownUp } from '@phosphor-icons/react';
import FarticoinLogo from '../../assets/Farticoin.png'; 
import EthLogo from '../../assets/ethlogo.png'; 
import DexVideo from '../../assets/dex.mp4';

const VideoBackground = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 1.5rem;
  overflow: hidden;
  z-index: 0;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6; 
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.5) 0%,
      rgba(26, 47, 15, 0.5) 100%
    );
    backdrop-filter: blur(3px);
  }
`;

const LaunchMessage = styled(motion.div)`
  position: absolute;
  top: -30px;
  left: 20%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #4c8c2f, #3a6b24);
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.4);
  z-index: 2;

  h3 {
    color: var(--accent-gold, #FFC107);
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const SwapCard = styled.div`
  width: 100%;
  max-width: 400px;
  background: transparent;
  border-radius: 1.5rem;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.3);

  @media (max-width: 768px) {
    display: none;
  }
`;

const SwapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;

  h2 {
    color: #ffffff;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const WalletButton = styled(motion.button)`
  background: #3a6b24;
  border: none;
  border-radius: 0.75rem;
  padding: 0.75rem 1.25rem;
  color: #ffffff;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #4c8c2f;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const SwapBox = styled.div`
  background: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 1.25rem;
  margin-bottom: 1.8rem; 
  position: relative;
  z-index: 1;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(76, 140, 47, 0.2);
`;
const SwapBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const SwapLabel = styled.span`
  color: #ffffff;
  font-size: 0.875rem;
  opacity: 0.8;
`;

const SwapInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const TokenAmount = styled.div`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 0.7;
`;

const TokenSelector = styled.button`
  background: #4c8c2f;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #3a6b24;
  }
`;

const SwapArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
`;

const SwapArrowButton = styled(motion.button)`
  background: #4c8c2f;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #3a6b24;
  }
`;

const SwapButton = styled(motion.button)`
  background: #4c8c2f;
  border: none;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  color: #ffffff;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  margin-top: 1.5rem;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    background: #3a6b24;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

    &::before {
      left: 100%;
    }
  }
`;

const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TokenIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
`;

const TokenSymbol = styled.span`
  color: #ffffff;
  font-weight: 600;
`;

const HeroSwapInterface = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const messageVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        delay: 0.5,
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const buttonVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 }
  };

  const arrowVariants = {
    hover: { rotate: 180 },
    tap: { scale: 0.9 }
  };

  return (
    <motion.div style={{ position: 'relative' }}>
      <LaunchMessage
        variants={messageVariants}
        initial="hidden"
        animate="visible"
      >
        <h3>DEX Coming Soon at Launch! 🚀</h3>
      </LaunchMessage>

      <SwapCard
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
     <VideoBackground>
  <video 
    autoPlay 
    loop 
    muted 
    playsInline
    style={{ opacity: 0.6 }}
  >
    <source src={DexVideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</VideoBackground>
        <SwapHeader>
          <h2>Buy $FARTY</h2>
          <WalletButton
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <Wallet size={20} />
            Connect Wallet
          </WalletButton>
        </SwapHeader>

        <SwapBox>
          <SwapBoxHeader>
            <SwapLabel>From</SwapLabel>
            <SwapLabel>Balance: 0</SwapLabel>
          </SwapBoxHeader>
          <SwapInput>
            <TokenAmount>0.0</TokenAmount>
            <TokenSelector>
              <TokenInfo>
                <TokenIcon src={EthLogo} alt="ETH" />
                <TokenSymbol>ETH</TokenSymbol>
              </TokenInfo>
            </TokenSelector>
          </SwapInput>
        </SwapBox>

        <SwapArrowContainer>
          <SwapArrowButton
            variants={arrowVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <ArrowsDownUp size={20} color="#ffffff" />
          </SwapArrowButton>
        </SwapArrowContainer>

        <SwapBox>
          <SwapBoxHeader>
            <SwapLabel>To</SwapLabel>
            <SwapLabel>Balance: 0</SwapLabel>
          </SwapBoxHeader>
          <SwapInput>
            <TokenAmount>0.0</TokenAmount>
            <TokenSelector>
              <TokenInfo>
                <TokenIcon src={FarticoinLogo} alt="FART" />
                <TokenSymbol>$FARTY</TokenSymbol>
              </TokenInfo>
            </TokenSelector>
          </SwapInput>
        </SwapBox>

        <SwapButton
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
        >
          Connect Wallet
        </SwapButton>
      </SwapCard>
    </motion.div>
  );
};

export default HeroSwapInterface;