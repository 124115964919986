import { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import FartItem from './FartItem';

// Import all your assets
import fartx1 from "../assets/farts/fart1.mp3";
import fartx2 from "../assets/farts/fart2.mp3";
import fartx3 from "../assets/farts/fart3.mp3";
import fartx4 from "../assets/farts/fart4.wav";
import fartx5 from "../assets/farts/fart5.mp3";
import fartx6 from "../assets/farts/fart6.mp3";
import fartx7 from "../assets/farts/fart7.mp3";
import fartx8 from "../assets/farts/fart8.mp3";
import fartx9 from "../assets/farts/fart9.mp3";
import fart1 from "../assets/farts/fart1.webm";
import fart2 from "../assets/farts/fart2.webm";
import fart3 from "../assets/farts/fart3.webm";
import fart4 from "../assets/farts/fart4.webm";
import fart5 from "../assets/farts/fart41.webm";
import fart6 from "../assets/farts/fart5.webm";
import fart7 from "../assets/farts/fart6.webm";
import fart8 from "../assets/farts/fart7.webm";

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const gridItemFadeIn = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const gridItemFadeOut = keyframes`
  from { 
    opacity: 1; 
    transform: translateY(0);
  }
  to { 
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const FartGridContainer = styled.section`
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;

  ${media.md} {
    padding: 5rem 1.5rem;
  }

  ${media.lg} {
    padding: 6rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  a {
    color: #ffcc00;
    text-decoration: none;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
    transition: all 0.2s ease;
    font-weight: 600;
    
    &:hover {
      text-shadow: 0 0 10px rgba(255, 204, 0, 0.6);
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: 1rem 0;
  transform: translateZ(0);

  ${media.sm} {
    gap: 2rem;
  }

  ${media.md} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.lg} {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
`;

const GridItem = styled.div`
  opacity: 0;
  animation: ${gridItemFadeIn} 0.3s ease-out forwards;
  animation-delay: ${props => props.$index * 0.1}s;

  &.removing {
    animation: ${gridItemFadeOut} 0.3s ease-out forwards;
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    opacity: 1;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  font-family: 'Exo 2', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  max-width: 200px;
  transform: translateZ(0);

  &:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: background 0.2s ease;
    &:hover {
      transform: none;
    }
  }

  ${media.md} {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
`;

const AddButton = styled(Button)`
  background: #4c8c2f;
  margin: 2rem auto 0;
  display: block;
  width: fit-content;

  &:hover {
    background: #3a6b24;
  }
`;

const FartGridComponent = () => {
  const [visibleItems, setVisibleItems] = useState(8);
  const [removingItems, setRemovingItems] = useState(false);
  const gridRef = useRef(null);

  const telegramUsers = [
    'cryptofarter', 'fartmaster', 'gasking', 'fartnite', 
    'tootlord', 'gassyvibes', 'fartistic', 'windbreaker'
  ];

  const fartItems = [
    { 
      emoji: fart7, 
      audioSrc: fartx1, 
      walletAddress: "0x73C585A6D48390CA21223fFdE93D0bfAc90d7b4f",
      telegramUsername: telegramUsers[0]
    },
    { 
      emoji: fart6, 
      audioSrc: fartx2, 
      walletAddress: "0x967738A92D194615018eD2553DAdAAcF78f9E0E6",
      telegramUsername: telegramUsers[1]
    },
    { 
      emoji: fart5, 
      audioSrc: fartx3, 
      walletAddress: "0x96C43dbdE612101Ea1D53142316452F5e46F4738",
      telegramUsername: telegramUsers[2]
    },
    { 
      emoji: fart4, 
      audioSrc: fartx4, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[3]
    },
    { 
      emoji: fart8, 
      audioSrc: fartx5, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[4]
    },
    { 
      emoji: fart1, 
      audioSrc: fartx6, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[5]
    },
    { 
      emoji: fart2, 
      audioSrc: fartx7, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[6]
    },
    { 
      emoji: fart3, 
      audioSrc: fartx8, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[7]
    },
    { 
      emoji: fart1, 
      audioSrc: fartx9, 
      walletAddress: "0x967738A92D194615018eD2553DAdAAcF78f9E0E6",
      telegramUsername: telegramUsers[0]
    },
    { 
      emoji: fart2, 
      audioSrc: fartx7, 
      walletAddress: "0x807e419933110f7E9F90348c4D2734801ac70138",
      telegramUsername: telegramUsers[1]
    }
  ];

  const handleShowMore = () => {
    const newVisibleItems = Math.min(visibleItems + 8, fartItems.length);
    setVisibleItems(newVisibleItems);
  };

  const handleShowLess = () => {
    setRemovingItems(true);
    setTimeout(() => {
      setVisibleItems(8);
      setRemovingItems(false);
      gridRef.current?.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start',
        inline: 'nearest'
      });
    }, 300); // Match animation duration
  };

  const handleAddFart = () => {
    window.open('https://t.me/FartilandBot', '_blank', 'noopener,noreferrer');
  };

  return (
    <FartGridContainer ref={gridRef} id="fart-grid">
      <ContentWrapper>
        <Title>The Fart Menagerie</Title>
        <Description>
          Explore below the uproarious collection of user-uploaded farts and the likes received.
          <br /><br />
          <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
            Want your fart to be featured here?
          </span>
          <br /><br />
          Upload it on our{' '}
          <a 
            href="https://t.me/FartilandBot" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Telegram channel
          </a>
          , your fart will be added here once it receives 10 likes.
        </Description>

        <GridContainer>
          {fartItems.slice(0, visibleItems).map((item, index) => (
            <GridItem 
              key={`${item.telegramUsername}-${index}`}
              $index={index}
              className={removingItems ? 'removing' : ''}
            >
              <FartItem
                emoji={item.emoji}
                audioSrc={item.audioSrc}
                walletAddress={item.walletAddress}
                telegramUsername={item.telegramUsername}
              />
            </GridItem>
          ))}
        </GridContainer>

        {fartItems.length > 8 && (
          <ButtonContainer>
            {visibleItems < fartItems.length && (
              <Button onClick={handleShowMore}>
                Show More
              </Button>
            )}
            {visibleItems > 8 && (
              <Button onClick={handleShowLess}>
                Show Less
              </Button>
            )}
          </ButtonContainer>
        )}

        <AddButton onClick={handleAddFart}>
          ADD YOUR FART(S)
        </AddButton>
      </ContentWrapper>
    </FartGridContainer>
  );
};

export default FartGridComponent;