// src/components/common/NavBackground.js
import React from 'react';
import styled from 'styled-components';

const simplePulse = `
  @keyframes simplePulse {
    0%, 100% { opacity: 0.8; }
    50% { opacity: 0.6; }
  }
`;

const NavBackgroundContainer = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, #4c8c2f 0%, #3d7025 50%, #2a5517 100%);
  isolation: isolate;
  transform: translateZ(0);
  z-index: -1;
  transition: all 0.3s ease;
  opacity: ${props => props.$isScrolled ? 0.98 : 0.95};
`;

const BackgroundGradient = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(76, 140, 47, 0.8),
    rgba(60, 110, 37, 0.8) 50%,
    rgba(76, 140, 47, 0.8)
  );
  opacity: 0.8;
  mix-blend-mode: multiply;

  @media (max-width: 768px) {
    background: linear-gradient(
      45deg,
      rgba(76, 140, 47, 0.9),
      rgba(60, 110, 37, 0.9) 50%,
      rgba(76, 140, 47, 0.9)
    );
  }
`;

const BackgroundPattern = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.4;
  background-image: 
    linear-gradient(
      rgba(106, 176, 76, 0.1) 1px, 
      transparent 1px
    ),
    linear-gradient(
      90deg, 
      rgba(106, 176, 76, 0.1) 1px, 
      transparent 1px
    );
  background-size: 20px 20px;
  transform: translateZ(0);

  @media (max-width: 768px) {
    opacity: 0.3;
    background-size: 15px 15px;
  }
`;

const StaticBubbles = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
  overflow: hidden;
`;

const StaticBubble = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(126, 196, 96, 0.4),
    rgba(76, 140, 47, 0.3) 50%,
    rgba(42, 85, 23, 0.2)
  );
  border-radius: 50%;
  opacity: 0.6;
  animation: ${props => props.animate ? 'simplePulse 3s ease-in-out infinite' : 'none'};
  transform: translateZ(0);

  @media (max-width: 768px) {
    width: ${props => props.size * 0.8}px;
    height: ${props => props.size * 0.8}px;
    animation: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    transparent 0%,
    rgba(42, 85, 23, 0.1) 70%
  );
  pointer-events: none;
  mix-blend-mode: multiply;
`;

const NavBackground = ({ isScrolled }) => {
  const isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  const isMobile = window.innerWidth <= 768;

  // Smaller bubbles for the nav
  const bubbles = [
    { size: 60, style: { top: '-20%', left: '-5%' }, animate: !isMobile },
    { size: 50, style: { top: '60%', right: '-5%' }, animate: !isMobile },
    { size: 30, style: { top: '20%', left: '25%' }, animate: false },
    { size: 40, style: { top: '40%', left: '70%' }, animate: false },
    { size: 25, style: { top: '65%', left: '40%' }, animate: false }
  ];

  return (
    <NavBackgroundContainer $isScrolled={isScrolled}>
      <style>{simplePulse}</style>
      <BackgroundGradient />
      <BackgroundPattern />
      <StaticBubbles>
        {bubbles.map((bubble, index) => (
          <StaticBubble
            key={index}
            size={bubble.size}
            style={bubble.style}
            animate={bubble.animate && !isReducedMotion}
          />
        ))}
      </StaticBubbles>
      <Overlay />
      {isScrolled && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: 'rgba(255, 255, 255, 0.1)',
          }}
        />
      )}
    </NavBackgroundContainer>
  );
};

export default NavBackground;