import React from 'react';
import styled from 'styled-components';
import { Wallet, Coins, ArrowsLeftRight } from '@phosphor-icons/react';

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const HowToBuyContainer = styled.section`
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }

  ${media.md} {
    padding: 5rem 1.5rem;
  }

  ${media.lg} {
    padding: 6rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin: 2rem 0;

  ${media.lg} {
    gap: 2.5rem;
  }

  @media (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 2rem auto;
    gap: 1.5rem;
  }
`;

const StepCard = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.3);
  position: relative;
  text-align: center;
  transform: translateZ(0);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.1) 0%,
      rgba(76, 140, 47, 0.05) 100%
    );
    z-index: 0;
    border-radius: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
    &:hover {
      transform: none;
    }
  }
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  
  ${StepCard}:hover & {
    transform: scale(1.05);
  }
  
  svg {
    width: 40px;
    height: 40px;
    color: #ffffff;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const StepTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background: #ffffff;
    opacity: 0.8;
  }
`;

const StepDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #ffffff;
  position: relative;
  z-index: 1;
  opacity: 0.9;
`;

const BuyButton = styled.button`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #ffffff;
  background: #4c8c2f;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  transform: translateZ(0);
  
  &:hover {
    background: #3a6b24;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
    &:hover {
      transform: none;
    }
  }
`;

const HowToBuy = () => {
  const steps = [
    {
      icon: <Wallet weight="duotone" />,
      title: "Create a Wallet",
      description: "Download Metamask from the App Store or Google Play Store. For desktop users, download the Google Chrome extension."
    },
    {
      icon: <Coins weight="duotone" />,
      title: "Get Some ETH",
      description: "Ensure you have ETH in your wallet to swap for $FARTY. Buy ETH from any exchange and send it to your wallet."
    },
    {
      icon: <ArrowsLeftRight weight="duotone" />,
      title: "Swap for $FARTY",
      description: "Connect to Uniswap, paste the $FARTY token address, and confirm the swap. Sign the transaction when prompted."
    }
  ];

  const handleBuyClick = () => {
    window.open('https://raydium.io/swap', '_blank', 'noopener,noreferrer');
  };

  return (
    <HowToBuyContainer id="how-to-buy">
      <ContentWrapper>
        <Title>How to Buy $FARTY</Title>
        <StepsGrid>
          {steps.map((step, index) => (
            <StepCard key={index}>
              <IconWrapper>
                {step.icon}
              </IconWrapper>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepCard>
          ))}
        </StepsGrid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BuyButton onClick={handleBuyClick}>
            Buy $FARTY Now!
          </BuyButton>
        </div>
      </ContentWrapper>
    </HowToBuyContainer>
  );
};

export default HowToBuy;