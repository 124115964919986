import React from 'react';
import styled, { keyframes } from 'styled-components';

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(-10px); }
  to { opacity: 1; transform: translateX(0); }
`;

const RoadmapContainer = styled.section`
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;

  ${media.md} {
    padding: 5rem 1.5rem;
  }

  ${media.lg} {
    padding: 6rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const PhasesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;

  ${media.lg} {
    gap: 2.5rem;
  }

  @media (max-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const PhaseCard = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-out forwards;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.1) 0%,
      rgba(76, 140, 47, 0.05) 100%
    );
    z-index: 0;
  }

  &:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    opacity: 1;
    &:hover {
      transform: none;
    }
  }

  animation-delay: ${props => props.$index * 0.1}s;
`;

const PhaseTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.5rem, 2vw, 1.75rem);
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4),
               0 0 20px rgba(76, 140, 47, 0.2),
               2px 2px 0 #8b4513;
  -webkit-text-stroke: 1px #4c8c2f;
  position: relative;
  padding-bottom: 1rem;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 2px;
    background: linear-gradient(to right, transparent, #ffffff, transparent);
    opacity: 0.8;
  }
`;

const PhaseList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  z-index: 1;
`;

const PhaseItem = styled.li`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff;
  padding: 0.75rem 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0;
  animation: ${slideIn} 0.3s ease-out forwards;
  animation-delay: ${props => props.$index * 0.05 + 0.2}s;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
    transform: translateX(5px);
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    opacity: 1;
    &:hover {
      transform: none;
    }
  }
`;

const Roadmap = () => {
  const phaseData = [
    {
      title: "Phase 1: Launch",
      items: [
        "Community Building",
        "$FARTY Contract Creation",
        "$FARTY Token Marketing",
        "$FARTY Contests for Community",
        "Beta version of the farting GAME",
        "Fart upload and Chat Platform",
        "Partnerships with Fart-Friendly Projects"
      ]
    },
    {
      title: "Phase 2: Growth",
      items: [
        "Launch on Dex",
        "Coingecko & CoinMarketCap Listings",
        "Tier 1 CEX Listings",
        "Major $FARTY Marketing Campaign",
        "1,500+ $FARTY Holders",
        "Develop $FARTY Fart Game Two",
        "Expand $FARTY Ecosystem"
      ]
    },
    {
      title: "Phase 3: Moon",
      items: [
        "Binance and Coinbase Listing",
        "$100M $FARTY Market Cap",
        "Fancy Merch for $FARTY Fans",
        "10,000+ $FARTY Holders",
        "$FARTY Explorer and Wallet",
        "Release $FARTY Fart Game Three",
        "Host $FARTY Tournament"
      ]
    }
  ];

  return (
    <RoadmapContainer id="roadmap">
      <ContentWrapper>
        <Title>Fartastic Roadmap</Title>
        <PhasesGrid>
          {phaseData.map((phase, index) => (
            <PhaseCard key={index} $index={index}>
              <PhaseTitle>{phase.title}</PhaseTitle>
              <PhaseList>
                {phase.items.map((item, itemIndex) => (
                  <PhaseItem key={itemIndex} $index={itemIndex}>
                    {item}
                  </PhaseItem>
                ))}
              </PhaseList>
            </PhaseCard>
          ))}
        </PhasesGrid>
      </ContentWrapper>
    </RoadmapContainer>
  );
};

export default Roadmap;