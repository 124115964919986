// HeroSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import HeroSwapInterface from './HeroSwapInterface';
import dexscreener from "../../assets/header/dexscreener.webp";
import coinmarketcap from "../../assets/header/coinmarketcap.webp";
import dexview from "../../assets/header/dexview.webp";
import dexstools from "../../assets/header/dex.webp";
import xlogo from "../../assets/logo-white.png";
import tlogo from "../../assets/header/telegram.webp";
import animatedLogo from "../../assets/herologo.webp";

// Breakpoint system (matching NavBar)
const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Animations
const fadeIn = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

const HeroContainer = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem 2rem; 

  ${media.md} {
    min-height: 90vh;
    padding: 5rem 1.5rem 2.5rem;

  ${media.lg} {
    padding: 5.5rem 2rem 3rem; 
  }
`;

// Adjusted content wrapper
const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 3rem; 
  animation: ${fadeIn} 0.6s ease-out;
  padding: 0 0.5rem; 

  ${media.md} {
    padding: 0 1rem; 
    gap: 2.5rem;
  }

  ${media.lg} {
    padding: 0 1.5rem; 
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  max-width: 650px;

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const SwapContainer = styled.div`
  width: 100%;
  max-width: 400px;
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const AnimatedFigureContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Title = styled.h1`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }

  @media (max-width: ${breakpoints.md}) {
    -webkit-text-stroke: 1px #4c8c2f;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6),
                 0 0 16px rgba(76, 140, 47, 0.3),
                 2px 2px 0 #8b4513;
  }
`;

const SocialIconsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 2rem 0;

  @media (max-width: ${breakpoints.md}) {
    justify-content: center;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
  }
`;


const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  img {
    width: 3rem;
    height: 3rem;
    object-fit: contain;

    @media (max-width: ${breakpoints.md}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.md}) {
    justify-content: center;
    gap: 0.75rem;
  }
`;

const ActionButton = styled(Link)`
  padding: 0.75rem 1.5rem;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #ffffff;
  background-color: ${({ $variant }) => 
    $variant === 'primary' ? '#8b4513' :
    $variant === 'secondary' ? '#4b2c20' :
    '#4c8c2f'};
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
  }
`;

const AnimatedFigure = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1;
  animation: ${float} 3s ease-in-out infinite;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: ${breakpoints.md}) {
    max-width: 300px;
  }
`;
const socialLinks = [
  {
    id: 'telegram',
    url: 'https://t.me/Fartyland',
    icon: tlogo,
    alt: 'Telegram'
  },
  {
    id: 'twitter',
    url: 'https://x.com/fartyland',
    icon: xlogo,
    alt: 'Twitter'
  },
  {
    id: 'dexscreener',
    url: 'https://dexscreener.com/ethereum/0x1234567890',
    icon: dexscreener,
    alt: 'Dexscreener'
  },
  {
    id: 'coinmarketcap',
    url: 'https://coinmarketcap.com/currencies/FARTY/',
    icon: coinmarketcap,
    alt: 'CoinMarketCap'
  },
  {
    id: 'dexview',
    url: 'https://dexview.com/eth/0x1234567890',
    icon: dexview,
    alt: 'Dexview'
  },
  {
    id: 'dextools',
    url: 'https://www.dextools.io/app/uniswap/pair-explorer/0x1234567890',
    icon: dexstools,
    alt: 'Dextools'
  }
];

const actionButtons = [
  {
    id: 'buy',
    label: 'Buy $FARTY',
    variant: 'primary',
    to: '#',
    external: true
  },
  {
    id: 'charts',
    label: 'View Charts',
    variant: 'secondary',
    to: '#',
    external: true
  },
  {
    id: 'game',
    label: 'Play Farty Game',
    variant: 'tertiary',
    to: '/game',
    external: false
  }
];

const HeroSection = () => {
  return (
    <HeroContainer>
      <ContentWrapper>
        <LeftColumn>
          <Title>Farty in Fartyland</Title>
          
          <SectionDescription>
            Not a cat or a dog, but our unique patented character{' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
              "Farty"
            </span>{' '}
            on a mission!
            <br /><br />
            Meet Farty, leading the charge for {' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
            digestive health awareness. 
            </span>{' '}
            
            Play the
            {' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
             fart-timing game,
            </span>{' '}
            
            earn
             {' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
            $FARTY,
            </span>{' '}
            
              and help in research and  raise awareness 
            for 
            {' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
            Irritable Bowel Syndrome (IBS)
            </span>{' '}
            , a functional gastrointestinal disorder, affecting about
            {' '}
            <span style={{ color: '#ffcc00', textShadow: '0 0 5px rgba(255, 204, 0, 0.8)' }}>
            15% 
            </span>{' '}
             of the world population.
          </SectionDescription>

          <SocialIconsGrid>
            {socialLinks.map(({ id, url, icon, alt }) => (
              <SocialLink
                key={id}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={alt}
              >
                <img src={icon} alt={alt} loading="lazy" />
              </SocialLink>
            ))}
          </SocialIconsGrid>

          <ButtonGroup>
            {actionButtons.map(({ id, label, variant, to, external }) => 
              external ? (
                <ActionButton
                  key={id}
                  as="a"
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                  $variant={variant}
                >
                  {label}
                </ActionButton>
              ) : (
                <ActionButton
                  key={id}
                  to={to}
                  $variant={variant}
                >
                  {label}
                </ActionButton>
              )
            )}
          </ButtonGroup>
        </LeftColumn>

        <RightColumn>
  <SwapContainer>
    <HeroSwapInterface />
  </SwapContainer>
  <AnimatedFigureContainer>
    <AnimatedFigure>
      <img 
        src={animatedLogo} 
        alt="Farty Character"
        loading="lazy"
      />
   
    </AnimatedFigure>
  </AnimatedFigureContainer>
</RightColumn>
      </ContentWrapper>
    </HeroContainer>
  );
};



const SectionDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  margin: 0;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  max-width: 600px;

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    margin: 0 auto;
  }
`;

export default HeroSection;