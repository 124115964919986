import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Drop, Trophy, Users, ChartLineUp } from '@phosphor-icons/react';

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const TokenomicsContainer = styled.section`
  position: relative;
  padding: 3rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;

  ${media.md} {
    padding: 4rem 1.5rem;
  }

  ${media.lg} {
    padding: 5rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  span {
    color: #ffcc00;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  }
`;

const DistributionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 1rem 0;

  ${media.lg} {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const DistributionCard = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.1) 0%,
      rgba(76, 140, 47, 0.05) 100%
    );
    z-index: 0;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
    &:hover {
      transform: none;
    }
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
  transition: transform 0.2s ease;

  ${DistributionCard}:hover & {
    transform: scale(1.1);
  }

  svg {
    width: 35px;
    height: 35px;
    color: #ffffff;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const CardTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin: 0;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Percentage = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 2rem;
  font-weight: 800;
  color: #ffcc00;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
  position: relative;
  z-index: 1;
`;
const fillWidth = keyframes`
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
`;

const MeterContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 2rem auto;
  padding: 1rem;
  background: rgba(76, 140, 47, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 1rem;
  border: 1px solid rgba(76, 140, 47, 0.2);
  transform: translateZ(0);
`;

const FartMeter = styled.div`
  width: 100%;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  display: flex;
  margin: 1rem 0;
`;

const MeterSegment = styled.div`
  height: 100%;
  width: ${props => props.$percentage}%;
  background: ${props => props.$color};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: left;
  animation: ${fillWidth} 0.6s ease-out forwards;
  min-width: ${props => props.$percentage < 10 ? '5%' : '0'};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 100%
    );
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const MeterLabel = styled.span`
  position: absolute;
  font-family: 'Exo 2', sans-serif;
  font-size: 0.75rem;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
  white-space: nowrap;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);

  ${MeterSegment}:hover & {
    opacity: 1;
  }
`;

const MeterLegend = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  color: #ffffff;
`;

const LegendColor = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  background: ${props => props.color};
`;

const TotalSupplyCard = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  margin: 2rem auto;
  max-width: 600px;
  border: 1px solid rgba(76, 140, 47, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease;
  transform: translateZ(0);

  &:hover {
    transform: scale(1.02);
  }

  @media (prefers-reduced-motion: reduce) {
    &:hover {
      transform: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.1) 0%,
      rgba(76, 140, 47, 0.05) 100%
    );
    z-index: 0;
  }
`;

const TotalSupplyTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  position: relative;
  z-index: 1;
`;

const TotalSupplyAmount = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.75rem, 4vw, 2.5rem);
  font-weight: 800;
  color: #ffcc00;
  text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);
  position: relative;
  z-index: 1;

  span {
    color: #ffffff;
    font-size: 0.8em;
  }
`;

const TokenomicsSection = () => {
  const distributionData = [
    {
      title: "Liquidity Farts",
      percentage: 70,
      color: "#808000",
      icon: Drop
    },
    {
      title: "Fart Competitions",
      percentage: 20,
      color: "#98FB98",
      icon: Trophy
    },
    {
      title: "Team/Advisors",
      percentage: 5,
      color: "#D2B48C",
      icon: Users
    },
    {
      title: "Marketing",
      percentage: 5,
      color: "#FFFDD0",
      icon: ChartLineUp
    }
  ];

  return (
    <TokenomicsContainer id="tokenomics">
      <ContentWrapper>
        <Title>FARTONOMICS</Title>
        <Description>
          Discover the <span>hilarious</span> and <span>unique</span> tokenomics 
          of our fart-tastic project!
        </Description>

        <DistributionGrid>
          {distributionData.map((item, index) => (
            <DistributionCard key={index}>
              <IconWrapper>
                <item.icon weight="fill" />
              </IconWrapper>
              <CardTitle>{item.title}</CardTitle>
              <Percentage>{item.percentage}%</Percentage>
            </DistributionCard>
          ))}
        </DistributionGrid>

        <MeterContainer>
          <FartMeter>
            {distributionData.map((segment, index) => (
              <MeterSegment
                key={index}
                $percentage={segment.percentage}
                $color={segment.color}
                style={{ 
                  animationDelay: `${index * 0.1}s` 
                }}
              >
                <MeterLabel>{segment.percentage}%</MeterLabel>
              </MeterSegment>
            ))}
          </FartMeter>

          <MeterLegend>
            {distributionData.map((item, index) => (
              <LegendItem key={index}>
                <LegendColor color={item.color} />
                {item.title} - {item.percentage}%
              </LegendItem>
            ))}
          </MeterLegend>
        </MeterContainer>

        <TotalSupplyCard>
          <TotalSupplyTitle>Total $FARTY Supply</TotalSupplyTitle>
          <TotalSupplyAmount>
            1,000,000,000,000 <span>$FARTY</span>
          </TotalSupplyAmount>
        </TotalSupplyCard>
      </ContentWrapper>
    </TokenomicsContainer>
  );
};

export default TokenomicsSection;