// Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import GooeyButton from './common/GooeyButton';
import NavBackground from './common/NavBackground';
import logo from '../assets/fartyheader.png';

// Breakpoint system with more precise values
const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1000px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Theme colors
const colors = {
  primary: {
    light: '#4c8c2f',
    main: '#3a6b24',
    dark: '#2a4f1a'
  },
  secondary: '#8b4513',
  white: '#ffffff',
  accent: '#ffcc00'
};

// Animations with smoother timing
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideIn = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

// Enhanced glass effect
const glassEffect = css`

  &::before {
    opacity: 0.95;
  }
  backdrop-filter: blur(8px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
`;

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  height: 3.5rem;
  padding: 0.5rem 1rem;
  isolation: isolate;
  transition: all 0.3s ease;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: ${props => props.$isScrolled ? 
      'rgba(0, 0, 0, 0.1)' : 
      'transparent'
    };
    transition: background-color 0.3s ease;
    z-index: -1;
  }

  ${media.sm} {
    height: 4rem;
    padding: 0.5rem 1.5rem;
  }

  ${media.md} {
    height: 4.5rem;
    padding: 0.5rem 2rem;
  }

  ${media.lg} {
    height: 5rem;
    padding: 0.5rem 3rem;
  }
`;


const Logo = styled.img`
  height: 4rem;  // Increased base height
  width: auto;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  ${media.sm} {
    height: 4.5rem;  // Increased for small devices
  }

  ${media.md} {
    height: 5rem;    // Increased for medium devices
  }

  ${media.lg} {
    height: 5.5rem;  // Increased for large devices
  }
`;
// Refined Menu Items
const MenuItems = styled.ul`
  display: none;

  ${media.md} {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    display: ${({ isopen }) => (isopen ? 'flex' : 'none')};
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 0.5rem 0;
    ${glassEffect}
    animation: ${slideIn} 0.3s ease-out;
    height: auto;
    max-height: calc(100vh - 3.5rem);
    overflow-y: auto;
  }
`;

// Refined Menu Item
const MenuItem = styled.li`
  position: relative;
  padding: 0.35rem 0.75rem;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    text-align: center;
    padding: 0.75rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
      border-bottom: none;
    }
  }
`;

// Refined Menu Text
const MenuText = styled.span`
  font-family: 'Exo 2', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  background: linear-gradient(to right, ${colors.white}, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.accent};
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  ${media.lg} {
    font-size: 1rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 1rem;
    &::after {
      display: none;
    }
  }
`;

// Refined Hamburger Menu
const HamburgerMenu = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  z-index: 10;
  margin-left: 0.75rem;

  ${media.md} {
    display: none;
  }
`;

const HamburgerLine = styled.span`
  width: 100%;
  height: 2px;
  background: ${colors.white};
  border-radius: 4px;
  transition: all 0.2s ease-out;
  transform-origin: 1px;

  &:first-child {
    transform: ${({ isopen }) => isopen ? 'rotate(45deg)' : 'rotate(0)'};
  }

  &:nth-child(2) {
    opacity: ${({ isopen }) => isopen ? '0' : '1'};
    transform: ${({ isopen }) => isopen ? 'translateX(20px)' : 'translateX(0)'};
  }

  &:last-child {
    transform: ${({ isopen }) => isopen ? 'rotate(-45deg)' : 'rotate(0)'};
  }
`;
// Game Navigation Components with refined styling
const GameNavContainer = styled(NavbarContainer)`
  padding: 0.5rem 1rem;
  height: 3.5rem;

  ${media.md} {
    height: 4rem;
    padding: 0.5rem 2rem;
  }
`;

const GameNavLogo = styled(Logo)`
  height: 2.25rem;
  margin: 0 0.75rem;

  ${media.md} {
    height: 2.75rem;
  }
`;

const GameButton = styled(Link)`
  padding: 0.4rem 0.75rem;
  font-family: 'Exo 2', sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
  background-color: ${({ $variant }) => 
    $variant === 'primary' ? colors.primary.main : colors.secondary};
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    background-color: ${({ $variant }) => 
      $variant === 'primary' ? colors.primary.light : colors.secondary};
  }

  ${media.md} {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

// Action Button Container
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

// Menu Overlay
const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 998;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close menu on route change and click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Reset menu state on route change
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  // Improved smooth scroll with offset calculation
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navHeight = navRef.current?.offsetHeight || 0;
      const sectionTop = section.offsetTop - navHeight - 20; // Added 20px buffer

      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
      setIsMenuOpen(false);
    }
  };

  const navigationItems = [
   
    { id: 'game-section', label: 'Play Farting Game' },
    { id: 'about', label: 'About' },
    { id: 'hall-of-fame', label: 'Hall of Fame' },
    { id: 'roadmap', label: 'Roadmap' },
    { id: 'tokenomics', label: 'Tokenomics' },
    { id: 'how-to-buy', label: 'How to Buy' }
  ];

  // Game page navigation
  if (location.pathname === '/game') {
    return (
      <GameNavContainer>
        <GameButton to="/" $variant="primary">
          Back to Farty
        </GameButton>
        <GameNavLogo 
          src={logo} 
          alt="FARTYLAND"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />
        <GameButton 
          as="a"
          href="https://buy.farty.com" 
          target="_blank"
          rel="noopener noreferrer"
          $variant="secondary"
        >
          Buy $FARTY
        </GameButton>
      </GameNavContainer>
    );
  }

  // Main navigation
  return (
    <>
     <NavbarContainer ref={navRef} $isScrolled={isScrolled}>
     <NavBackground isScrolled={isScrolled} />
        
        <Link to="/">
          <Logo 
            src={logo} 
            alt="FARTYLAND"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </Link>

        <MenuItems isopen={isMenuOpen}>
        
          {navigationItems.map(({ id, label }) => (
            <MenuItem key={id}>
              <MenuText 
                onClick={() => scrollToSection(id)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => e.key === 'Enter' && scrollToSection(id)}
              >
                {label}
              </MenuText>
              

            </MenuItem>
          ))}
        </MenuItems>

        <ActionContainer>
          <GooeyButton />
          
          <HamburgerMenu
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
            aria-expanded={isMenuOpen}
          >
            <HamburgerLine isopen={isMenuOpen} />
            <HamburgerLine isopen={isMenuOpen} />
            <HamburgerLine isopen={isMenuOpen} />
          </HamburgerMenu>
        </ActionContainer>
      </NavbarContainer>

      <MenuOverlay 
        className={isMenuOpen ? 'active' : ''} 
        onClick={() => setIsMenuOpen(false)}
        
      />
    </>
  );
};

export default Navbar;