import React from 'react';
import styled from 'styled-components';
import webvideo from "../assets/webvideo.gif"

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const CTAContainer = styled.section`
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }

  ${media.md} {
    padding: 5rem 1.5rem;
  }

  ${media.lg} {
    padding: 6rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  text-align: center;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const CTAButton = styled.button`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  background: #4c8c2f;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.3s;
  }

  &:hover {
    background: #3a6b24;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(0);
  }

  @media (prefers-reduced-motion: reduce) {
    &:hover {
      transform: none;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  ${media.md} {
    max-width: 600px;
  }
`;

const GifImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const CTASection = () => {
  const handleBuyClick = () => {
    window.open('https://raydium.io/swap', '_blank', 'noopener,noreferrer');
  };

  return (
    <CTAContainer id="join-now">
      <ContentWrapper>
        <div>
          <Title>Join the Fart Revolution!</Title>
          <Description>
            Don't miss to be a part of the most hilarious 
            and unique meme project ever created.
            <br /><br />
            Buy $FARTY now and let your farts be heard across the blockchain.
          </Description>
        </div>

        <CTAButton onClick={handleBuyClick}>
          Buy $FARTY Now!
        </CTAButton>

        <ImageContainer>
          <GifImage
            src={webvideo}
            alt="Farty Animation"
            loading="lazy"
          />
        </ImageContainer>
      </ContentWrapper>
    </CTAContainer>
  );
};

export default CTASection;