import React, { useEffect, useRef,useState} from 'react';
import styled, { keyframes } from 'styled-components';
import GameIntroScreen from './GameIntroScreen';
import GameOver from './GameOver'

// Matching breakpoints with Nav and Hero
const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Refined animations
const fadeIn = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const commonSpacing = {
  mobile: {
    top: '3rem',     
    bottom: '1.5rem' 
  },
  tablet: {
    top: '3.5rem',   
    bottom: '2rem'  
  },
  desktop: {
    top: '4rem',     
    bottom: '2.5rem' 
  }
};



const GameSectionContainer = styled.section`
  position: relative;
  padding: ${commonSpacing.mobile.top} 1rem ${commonSpacing.mobile.bottom}; // Changed from 0.5rem
  text-align: center;
  overflow: hidden;
  perspective: 1000px;
  animation: ${fadeIn} 0.6s ease-out;

  ${media.md} {
    padding: ${commonSpacing.tablet.top} 1.5rem ${commonSpacing.tablet.bottom};
  }

  ${media.lg} {
    padding: ${commonSpacing.desktop.top} 2rem ${commonSpacing.desktop.bottom};
  }
`;


const GameContainer = styled.div`
  width: 95%; // Changed from 100%
  max-width: 1200px;
  margin: 1rem auto 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: #231F20;
  aspect-ratio: 16/9;
  border: 1px solid rgba(76, 140, 47, 0.3);
  box-shadow: 0 0 20px rgba(76, 140, 47, 0.2);

  ${media.md} {
    width: 100%;
    margin-top: 2rem;
    border-radius: 12px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-top: 0.5rem;
    aspect-ratio: 4/3;
  }

  @media (max-width: ${breakpoints.sm}) {
    aspect-ratio: 1/1;
  }
`;


// Description with consistent styling
const GameDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  margin: 0 auto 1.5rem;
  max-width: 800px;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 1rem;
    max-width: 90%;
    margin-bottom: 1rem;
  }
`;

const GameWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0; 

  ${media.md} {
    padding: 0;
  }

  ${media.lg} {
    padding: 0;
  }
`;






const GameTitle = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;
  display: inline-block; // For better underline positioning

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

// Loading overlay with refined styling
const LoaderOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(4px);
  z-index: 10;
`;

const LoaderSpinner = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
  position: relative;
  animation: ${float} 2s ease-in-out infinite;

  ${media.md} {
    width: 100px;
    height: 100px;
  }
`;

const SpinningCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #4c8c2f;
  animation: ${spin} 1.5s linear infinite;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #8b4513;
  }

  ${media.md} {
    width: 80px;
    height: 80px;

    &::before {
      width: 50px;
      height: 50px;
    }
  }
`;

const LoaderText = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.25rem, 2vw, 1.75rem);
  font-weight: 700;
  color: #4c8c2f;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 0 #8b4513;
`;
const GameSection = () => {
  const [loading, setLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [unityInstance, setUnityInstance] = useState(null);
  const canvasRef = useRef(null);

  // Enhanced cleanup on component unmount
  useEffect(() => {
    document.body.style.overflow = 'auto';
    
    return () => {
      if (unityInstance) {
        unityInstance.Quit();
      }
      cleanupUnityLoader();
    };
  }, [unityInstance]);

  const cleanupUnityLoader = () => {
    const loaderScript = document.querySelector('script[src="/Build/Build/Build.loader.js"]');
    if (loaderScript) {
      document.body.removeChild(loaderScript);
    }
  };

  // Improved game initialization
  const initializeGame = async () => {
    setLoading(true);
    setGameStarted(true);

    try {
      await cleanupAndInitLoader();
      await initializeUnityInstance();
    } catch (error) {
      console.error("Game initialization failed:", error);
      setLoading(false);
      setGameStarted(false);
    }
  };

  const cleanupAndInitLoader = async () => {
    cleanupUnityLoader();

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = '/Build/Build/Build.loader.js';
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializeUnityInstance = async () => {
    if (typeof window.createUnityInstance === "undefined") {
      throw new Error("Unity create instance function not found");
    }

    // Setup score callback
    window.receiveScoreFromUnity = (newScore) => {
      setScore(parseFloat(newScore));
      setGameOver(true);
    };

    try {
      const instance = await window.createUnityInstance(
        canvasRef.current,
        {
          dataUrl: "/Build/Build/Build.data.unityweb",
          frameworkUrl: "/Build/Build/Build.framework.js.unityweb",
          codeUrl: "/Build/Build/Build.wasm.unityweb",
          streamingAssetsUrl: "StreamingAssets",
          companyName: "DefaultCompany",
          productName: "Fartyland",
          productVersion: "0.1",
          devicePixelRatio: window.devicePixelRatio,
          matchWebGLToCanvasSize: true,
        },
        (progress) => {
          if (progress === 1) setLoading(false);
        }
      );

      setUnityInstance(instance);
    } catch (error) {
      throw new Error("Failed to create Unity instance");
    }
  };

  // Enhanced game state handlers
  const handlePlayAgain = () => {
    setGameOver(false);
    if (unityInstance) {
      try {
        unityInstance.SendMessage('GameManager', 'ResetGame');
      } catch (error) {
        console.error("Reset game failed:", error);
        handleMainMenu();
        setTimeout(initializeGame, 100);
      }
    }
  };

  const handleMainMenu = () => {
    setGameStarted(false);
    setGameOver(false);
    if (unityInstance) {
      unityInstance.Quit();
      setUnityInstance(null);
    }
    cleanupUnityLoader();
  };

  return (
    <GameSectionContainer id="game-section">
      <GameWrapper>
      <GameTitle>Play Farting Game</GameTitle>
      
      <GameDescription>
        Dive into the fun of Fartyland with our interactive Farting game!
      </GameDescription>
      
      <GameDescription>
        Fart with the sound of passing cars, please  read the instructions on how to play!
      </GameDescription>

      <GameContainer>
        {!gameStarted && (
          <GameIntroScreen 
          onPlayForFun={initializeGame}
            isLoading={loading}
          />
        )}
        
        {gameStarted && (
  <div 
    id="unity-container" // Add this ID
    style={{ width: '100%', height: '100%', position: 'relative' }}
  >
    <canvas 
      ref={canvasRef}
      id="unity-canvas" // Add this ID
      style={{ 
        width: '100%',
        height: '100%',
        display: 'block'
      }}
    />
    
    {loading && (
      <LoaderOverlay>
        <LoaderSpinner>
          <SpinningCircle />
        </LoaderSpinner>
        <LoaderText>Loading Fartyland...</LoaderText>
      </LoaderOverlay>
    )}
  </div>
)}

        {gameOver && (
          <GameOver
            score={score}
            onPlayAgain={handlePlayAgain}
            onMainMenu={handleMainMenu}
          />
        )}
      </GameContainer>
      </GameWrapper>
    </GameSectionContainer>
  );
};


GameSection.propTypes = {

};

export default GameSection;