import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Avocado } from '@phosphor-icons/react';

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const FartItemContainer = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  aspect-ratio: 1/1.2;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${breakpoints.md}) {
    aspect-ratio: 1/0.8;
    padding: 1rem;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.1;
  transition: opacity 0.3s ease;
  border-radius: 1rem;

  ${FartItemContainer}:hover & {
    opacity: 0.2;
  }
`;

const PlayButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;

  &:hover {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.3);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: ${breakpoints.md}) {
    width: 50px;
    height: 50px;
    font-size: 1.25rem;
  }
`;

const LikeCounter = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 1.5rem;
  padding: 0.4rem 0.75rem;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 2;
  color: #ffffff;

  .like-icon {
    color: #4c8c2f;
    width: 1rem;
    height: 1rem;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 2;
`;

const TelegramUsername = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 0.875rem;
  color: #ffcc00;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.35rem 0.75rem;
  border-radius: 1rem;
  text-align: center;
  width: fit-content;
`;

const WalletAddress = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.2);
  padding: 0.35rem 0.75rem;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;
const FartItem = ({ emoji, audioSrc, walletAddress, telegramUsername }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  
  // Generate random like count between 100 and 1000
  const likeCount = useRef(Math.floor(Math.random() * (1000 - 100 + 1) + 100)).current;

  // Format wallet address for display (first 6...last 4)
  const formattedWallet = `${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`;

  // Handle media loading
  useEffect(() => {
    const audio = audioRef.current;
    const video = videoRef.current;

    const handleAudioLoad = () => setIsAudioLoaded(true);
    const handleVideoLoad = () => setIsVideoLoaded(true);

    if (audio) {
      audio.addEventListener('loadeddata', handleAudioLoad);
    }
    if (video) {
      video.addEventListener('loadeddata', handleVideoLoad);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('loadeddata', handleAudioLoad);
      }
      if (video) {
        video.removeEventListener('loadeddata', handleVideoLoad);
      }
    };
  }, []);

  // Handle play/pause
  useEffect(() => {
    const handlePlay = async () => {
      try {
        if (isPlaying && audioRef.current && videoRef.current) {
          const audioPromise = audioRef.current.play();
          const videoPromise = videoRef.current.play();
          
          // Handle potential play() promise rejections
          if (audioPromise !== undefined) {
            audioPromise.catch(error => {
              console.warn("Audio playback failed:", error);
              setIsPlaying(false);
            });
          }
          
          if (videoPromise !== undefined) {
            videoPromise.catch(error => {
              console.warn("Video playback failed:", error);
            });
          }
        }
      } catch (error) {
        console.warn("Media playback error:", error);
        setIsPlaying(false);
      }
    };

    const handlePause = () => {
      try {
        if (!isPlaying && audioRef.current && videoRef.current) {
          if (audioRef.current.pause && typeof audioRef.current.pause === 'function') {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
          
          if (videoRef.current.pause && typeof videoRef.current.pause === 'function') {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
          }
        }
      } catch (error) {
        console.warn("Media pause error:", error);
      }
    };

    if (isAudioLoaded && isVideoLoaded) {
      if (isPlaying) {
        handlePlay();
      } else {
        handlePause();
      }
    }

    // Cleanup function
    return () => {
      try {
        if (audioRef.current && typeof audioRef.current.pause === 'function') {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        if (videoRef.current && typeof videoRef.current.pause === 'function') {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      } catch (error) {
        console.warn("Cleanup error:", error);
      }
    };
  }, [isPlaying, isAudioLoaded, isVideoLoaded]);

  const handlePlayClick = () => {
    setIsPlaying(prev => !prev);
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
    try {
      if (videoRef.current && typeof videoRef.current.pause === 'function') {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    } catch (error) {
      console.warn("Error handling audio end:", error);
    }
  };

  const handleCopyWallet = () => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => {
        console.log('Wallet address copied!');
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  return (
    <FartItemContainer>
      <VideoBackground
        ref={videoRef}
        src={emoji}
        loop
        muted
        playsInline // Add this for better iOS support
        preload="auto" // Add this for better loading behavior
      />
      <PlayButton 
        onClick={handlePlayClick}
        aria-label={isPlaying ? "Pause fart sound" : "Play fart sound"}
        disabled={!isAudioLoaded || !isVideoLoaded} // Disable button until media is loaded
      >
        {isPlaying ? '❚❚' : '▶'}
      </PlayButton>
      <audio
        ref={audioRef}
        src={audioSrc}
        onEnded={handleAudioEnd}
        preload="auto" // Add this for better loading behavior
      />
      <LikeCounter>
        <Avocado weight="fill" className="like-icon" />
        {likeCount}
      </LikeCounter>
      <InfoContainer>
        <TelegramUsername>
          @{telegramUsername || 'anonymous'}
        </TelegramUsername>
        <WalletAddress 
          onClick={handleCopyWallet}
          title="Click to copy full address"
        >
          {formattedWallet}
        </WalletAddress>
      </InfoContainer>
    </FartItemContainer>
  );
};

export default FartItem;